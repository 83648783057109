<template>
  <b-card class="checkout--card">
    <page-loading :show="loading" />
    <div class="wrapper--title u--between mb-4" :class="{ 'mt-4': $mq === 'xs' }">
      <h2>{{ $t('general.paymentInfo') }}</h2>
    </div>
    <div class="wrapper--form">
      <form @submit.prevent="submit">
        <billing-information ref="billingInfo" />
      </form>
    </div>
  </b-card>
</template>

<script>
const BillingInformation = () => import('@/components/checkout/billing-information');
const PageLoading = () => import('@/components/content-loading/page-loading');
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'checkout-form',
  mixins: [HelperMixin],
  computed: {
    ...mapState({
      loading: state => state.checkout.loading,
    }),
  },
  components: {
    BillingInformation,
    // PaymentMethod,
    PageLoading,
  },
  methods: {
    async submit() {
      this.$emit('submit');
    },
  },
};
</script>

<style scoped></style>
